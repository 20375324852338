<template>
  <b-modal
    title="Configuración de Coins"
    id="configCoins"
    size="xl"
    hide-footer
    hide-header
    body-class="body-modal-custom"
  >
    <form @submit.prevent="saveConfigCoins">
      <div class="row">
        <!-- lista de configuraciones -->
        <div class="col-12 col-lg-4">
          <div class="d-flex align-items-center justify-content-between">
            <h3 class="font-weight-bold">Configuraciones</h3>
            <vs-button
              icon
               
              danger
              @click.native.prevent="handlerForAddConfig"
            >
              <i class="fas fa-plus-square"></i>
            </vs-button>
          </div>
          <ul
            class="mt-2"
            style="
              overflow-y: auto;
              height: 100%;
              min-height: 50px;
              max-height: 400px;
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            "
          >
            <li
              v-for="(config, index) of listConfigCoinsCanjes"
              :key="config.id"
              class="p-3 bg-dark rounded-right"
              :style="{
                transition: 'box-shadow 0.3s ease',
                boxShadow:
                  activeConfig == index ? 'inset 0 0 0 2px #186bdd' : 'none',
                cursor: 'default',
              }"
              @click="handlerConfig(config, index)"
            >
              <span>Config - {{ index + 1 }}</span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg">
          <div class="row">
            <!-- avatar -->
            <div class="col-4 mb-3">
              <label for="avatar" class="form-label"
                ><em class="font-weight-bolder">Avatar</em>
              </label>
              <b-form-input
                v-model="dataConfigCoinsCanjes.avatar"
                type="number"
                placeholder="Cantidad de coins..."
                required
              ></b-form-input>
            </div>
            <!-- onboarding -->
            <div class="col-4 mb-3">
              <label for="onboarding" class="form-label"
                ><em class="font-weight-bolder">Onboarding</em>
              </label>
              <b-form-input
                v-model="dataConfigCoinsCanjes.onboarding"
                type="number"
                placeholder="Cantidad de coins..."
                required
              ></b-form-input>
            </div>
            <!-- curriculum -->
            <div class="col-4 mb-3">
              <label for="onboarding" class="form-label"
                ><em class="font-weight-bolder">Curriculum</em>
              </label>
              <b-form-input
                v-model="dataConfigCoinsCanjes.curriculum"
                type="number"
                placeholder="Cantidad de coins..."
                required
              ></b-form-input>
            </div>
            <!-- tareas -->
            <div class="col-12 mb-3">
              <label for="tareas" class="form-label"
                ><em class="font-weight-bolder">Tareas</em>
              </label>
              <div class="row">
                <div class="col-4">
                  <!-- basico -->
                  <label for="basico" class="form-label"
                    ><em class="font-weight-bolder text-danger"
                      >Complejidad Básica</em
                    ></label
                  >
                  <br />
                  <!-- niveles de esfuerzo -->
                  <b class="text-light">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <br />
                  <em class="font-weight-bold text-white-50">1,2,3,5,7</em>
                  <b-form-input
                    class="mt-2"
                    v-model="dataConfigCoinsCanjes.tareaBasica"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>

                <div class="col-4">
                  <!-- medio -->
                  <label for="medio" class="form-label"
                    ><em class="font-weight-bolder text-warning"
                      >Complejidad Media</em
                    ></label
                  >
                  <br />
                  <!-- niveles de esfuerzo -->
                  <b class="text-light">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <br />
                  <em class="font-weight-bold text-white-50"
                    >11,13,17,19,23,29,31</em
                  >
                  <b-form-input
                    class="mt-2"
                    v-model="dataConfigCoinsCanjes.tareaMedia"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>

                <div class="col-4">
                  <!-- alto -->
                  <label for="alto" class="form-label"
                    ><em class="font-weight-bolder text-success"
                      >Complejidad Alta</em
                    ></label
                  >
                  <br />
                  <!-- niveles de esfuerzo -->
                  <b class="text-light">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <br />
                  <em class="font-weight-bold text-white-50">37,41,43,47</em>
                  <b-form-input
                    class="mt-2"
                    v-model="dataConfigCoinsCanjes.tareaAlta"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
              </div>
            </div>
            <!-- rango evaluaciones -->
            <div class="col-12">
              <label for="rangoEvaluaciones" class="form-label"
                ><em class="font-weight-bolder">Rangos Evaluaciones</em></label
              >
              <br />
              <div class="row">
                <div class="col-4 mb-3">
                  <!-- 50-59 -->
                  <label for="basico" class="form-label"
                    ><em class="font-weight-bolder text-white-50"
                      >50-59%</em
                    ></label
                  >
                  <b-form-input
                    v-model="dataConfigCoinsCanjes.rango_50_59"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
                <div class="col-4 mb-3">
                  <!-- 60-74% -->
                  <label for="medio" class="form-label"
                    ><em class="font-weight-bolder text-white-50"
                      >60-74%</em
                    ></label
                  >
                  <b-form-input
                    v-model="dataConfigCoinsCanjes.rango_60_74"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
                <div class="col-4 mb-3">
                  <!-- 75-84% -->
                  <label for="alto" class="form-label"
                    ><em class="font-weight-bolder text-white-50"
                      >75-84%</em
                    ></label
                  >
                  <b-form-input
                    v-model="dataConfigCoinsCanjes.rango_75_84"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
                <div class="col-6 mb-3">
                  <!-- 85-94% -->
                  <label for="alto" class="form-label"
                    ><em class="font-weight-bolder text-white-50"
                      >85-94%</em
                    ></label
                  >
                  <b-form-input
                    v-model="dataConfigCoinsCanjes.rango_85_94"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
                <div class="col-6">
                  <!-- 95%+ -->
                  <label for="alto" class="form-label"
                    ><em class="font-weight-bolder text-white-50"
                      >95%+</em
                    ></label
                  >
                  <b-form-input
                    v-model="dataConfigCoinsCanjes.rango_95_100"
                    type="number"
                    placeholder="Cantidad de coins..."
                    required
                  ></b-form-input>
                </div>
              </div>
            </div>

            <!-- seleccionar areas -->
            <div class="col-12 mb-3">
              <label for="areas" class="form-label">
                <em class="font-weight-bolder">Areas</em>
              </label>
              <!-- vuesax select multiple -->
              <vs-select
                class="custom-multiple"
                filter
                multiple
                v-model="dataConfigCoinsCanjes.areaIds"
                @click.native.prevent
              >
                <vs-option
                  v-for="area of listAreas"
                  :key="area.id"
                  :label="area.descripcion"
                  :value="area.id"
                >
                  {{ area.descripcion }}
                </vs-option>
              </vs-select>
            </div>
          </div>
        </div>
      </div>

      <!-- btn save config -->
      <div class="d-flex justify-content-end mt-3">
        <vs-button
          :loading="isSaveConfig"
          type="submit"
          class="py-1"
           
          size="large"
        >
          <i class="fas fa-circle-check mr-1"></i>
          Guardar
        </vs-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  props: {
    listAreas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeConfig: 0,
      isSaveConfig: false,
      dataConfigCoinsCanjes: {
        id: null,
        empresaId: null,
        areaIds: [],
        avatar: 0,
        onboarding: 0,
        curriculum: 0,
        tareaBasica: 0,
        tareaMedia: 0,
        tareaAlta: 0,
        rango_50_59: 0,
        rango_60_74: 0,
        rango_75_84: 0,
        rango_85_94: 0,
        rango_95_100: 0,
      },
      listConfigCoinsCanjes: [
        {
          id: null,
          empresaId: null,
          areaIds: [],
          avatar: 0,
          onboarding: 0,
          curriculum: 0,
          tareaBasica: 0,
          tareaMedia: 0,
          tareaAlta: 0,
          rango_50_59: 0,
          rango_60_74: 0,
          rango_75_84: 0,
          rango_85_94: 0,
          rango_95_100: 0,
        },
      ],
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listConfigCoinsCanjes = [];
    await this.getListConfigCoinsCanjesByEmpresa(this.persona.empresa.id);
    if (this.listConfigCoinsCanjes.length > 0)
      this.dataConfigCoinsCanjes = this.listConfigCoinsCanjes[0];
  },
  methods: {
    handlerConfig(config, index) {
      this.dataConfigCoinsCanjes = config;
      this.activeConfig = index;
    },
    handlerForAddConfig() {
      this.listConfigCoinsCanjes.push({
        id: null,
        empresaId: null,
        areaIds: [],
        avatar: 0,
        onboarding: 0,
        curriculum: 0,
        tareaBasica: 0,
        tareaMedia: 0,
        tareaAlta: 0,
        rango_50_59: 0,
        rango_60_74: 0,
        rango_75_84: 0,
        rango_85_94: 0,
        rango_95_100: 0,
      });
    },
    async getListConfigCoinsCanjesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ConfigCoinsCanjes/GetListByEmpresa/${empresaId}`,
        });
        // console.log("ConfigCoinsCanjes list...", res);
        if (res.success && res.data !== null) {
          this.listConfigCoinsCanjes = res.data.map((config) => ({
            ...config,
            areaIds: config.areaIds !== null ? config.areaIds.split(",") : [],
          }));
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async saveConfigCoins() {
      try {
        this.isSaveConfig = !this.isSaveConfig;

        let res;

        let { id, ...rest } = this.dataConfigCoinsCanjes;

        // console.log("dataaaaaaaaaaaaaaa<<<<", this.dataConfigCoinsCanjes);

        if (id !== null) {
          res = await this.$store.dispatch("hl_post", {
            path: "ConfigCoinsCanjes/Update/",
            data: {
              id,
              ...rest,
              areaIds: rest.areaIds.length !== 0 ? rest.areaIds.join() : null,
            },
          });

          // si existe config general
          if (!res.success) throw new Error(res.message);
        } else {
          res = await this.$store.dispatch("hl_post", {
            path: "ConfigCoinsCanjes/",
            data: {
              ...rest,
              empresaId: this.persona.empresa.id,
              areaIds: rest.areaIds.length !== 0 ? rest.areaIds.join() : null,
            },
          });

          // si existe config general
          if (!res.success) throw new Error(res.message);

          let index = this.listConfigCoinsCanjes.indexOf(
            this.dataConfigCoinsCanjes
          );

          if (index !== -1) {
            this.listConfigCoinsCanjes[index] = {
              ...rest,
              id: res.data,
              empresaId: this.persona.empresa.id,
              areaIds: rest.areaIds.length !== 0 ? rest.areaIds.join() : null,
            };

            this.dataConfigCoinsCanjes = {
              ...rest,
              id: res.data,
              empresaId: this.persona.empresa.id,
              areaIds: rest.areaIds.length !== 0 ? rest.areaIds.join() : null,
            };
          }
        }

        // console.log("res config coins --------->", res);

        if (res.success) {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Config agregada correctamente!",
          });
          this.isSaveConfig = !this.isSaveConfig;

          // cerrar modal
          this.$bvModal.hide("configCoins");
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isSaveConfig = !this.isSaveConfig;
      }
    },
  },
};
</script>

<style scoped></style>
