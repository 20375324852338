<template>
  <b-card>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <div class="d-flex flex-column align-items-start">
            <div class="d-flex">
              <vs-button flat success size="large" to="/helex/market">
                <i class="fas fa-store mr-1"></i>
                Ir a tienda
              </vs-button>
              <vs-button flat danger size="large" v-b-modal.history>
                <i class="fas fa-history mr-1"></i>
                Historial de canjes
              </vs-button>
              <vs-button flat warn size="large" v-b-modal.configCoins>
                <i class="fas fa-coins mr-1"></i>
                Configuración de coins
              </vs-button>
            </div>
            <h2 class="card-title">
              <b>BENEFICIOS MARKET</b>
            </h2>
          </div>
          <hr />
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col-12 col-md-8">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>

              <!-- btn send coins -->
              <div class="col-12 col-md">
                <BtnAddCardTable
                  text="Enviar coins"
                  icon="coins"
                  variant="warn"
                  v-b-modal.sendCoins
                />
              </div>
              <!-- btn new producto -->
              <div class="col-12 col-md">
                <BtnAddCardTable
                  text="Nuevo Beneficio"
                  v-b-modal.addBeneficio
                />
              </div>
            </div>
          </div>
          <!-- table managerMarket -->
          <div class="px-4 mt-3">
            <table
              id="datatable_managerMarket"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Categoría</th>
                  <th>Coins</th>
                  <th>Stock</th>
                  <th>Visibilidad</th>
                  <th>Activo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <!-- nombre -->
                  <td>
                    {{ item.nombre }}
                  </td>
                  <!-- descripcion -->
                  <td>
                    {{ item.descripcion }}
                  </td>
                  <!-- categoria -->
                  <td>{{ item.categoria?.nombre }}</td>
                  <!-- coins -->
                  <td>
                    {{ item.coins }}
                    <img
                      style="width: 20px"
                      loading="lazy"
                      src="/img/coins.png"
                      alt="coins"
                    />
                  </td>
                  <!-- stock -->
                  <td>{{ item.stock }}</td>
                  <!-- visibilidad -->
                  <td>
                    <h4>
                      <b-badge
                        :variant="`${
                          item.visibilidadAreaId === null ? 'success' : 'danger'
                        } `"
                      >
                        <i
                          :class="`fas ${
                            item.visibilidadAreaId === null
                              ? 'fa-earth-americas'
                              : 'fa-lock'
                          }`"
                        ></i>
                        {{
                          item.visibilidadAreaId === null
                            ? "Publica"
                            : "Privada"
                        }}
                      </b-badge>
                    </h4>
                  </td>
                  <!-- estado -->
                  <td>
                    <template>
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <vs-switch
                          v-model="item.estado"
                          @input="toggleActive(index, item)"
                        >
                          <template #off>
                            <i class="fas fa-xmark"></i>
                          </template>
                          <template #on>
                            <i class="fas fa-check"></i>
                          </template>
                        </vs-switch>
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- add beneficio modal -->
    <modalAddBeneficio
      :listAreas="listAreas"
      @updateData="updateTableAndListItems"
    />

    <!-- modal send coins -->
    <modalSendCoins :listAreas="listAreas" />

    <!-- modal config coins -->
    <modalConfigCoins :listAreas="listAreas" />

    <!-- modal historial de canjes -->
    <modalHistoryCanjes />
  </b-card>
</template>

<script>
import { core } from "@/config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "@/components/cardTable/CardTable.vue";
import BtnAddCardTable from "@/components/btnCardTable/btnAddCardTable.vue";

const Swal = require("sweetalert2");

// modals
import modalAddBeneficio from "./modalAddBeneficio_managerMarket.vue";
import modalSendCoins from "./modalSendCoins_managerMarket.vue";
import modalConfigCoins from "./modalConfigCoins_managerMarket.vue";
import modalHistoryCanjes from "./modalHistoryCanjes_managerMarket.vue";

export default {
  name: "managerMarket",
  components: {
    CardTable,
    BtnAddCardTable,
    modalAddBeneficio,
    modalSendCoins,
    modalConfigCoins,
    modalHistoryCanjes,
  },
  data() {
    return {
      persona: {},
      dataTableInstance: {},
      listAreas: [],
      listaItems: [
        {
          id: 1,
          nombre: "dia libre",
          descripcion: "descanso",
          categoriaId: "Tiempo Libre",
          imagenPreview: null,
          coins: 20,
          stock: 20,
          visibilidadAreaId: [],
          createdAt: null,
          responsableId: null,
          estado: true,
        },
      ],
      dataCards: [
        {
          title: "Activos",
          value: () => this.listaItems.filter((b) => b.estado).length,
          iconName: "circle-check",
          color: "#186bdd",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            // this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            // this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Inactivos",
          value: () => this.listaItems.filter((b) => !b.estado).length,
          iconName: "xmark-circle",
          color: "rgb(242, 19, 93)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            // this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            // this.dataTableInstance.search("").draw();
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);

    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    await this.getData();
    await this.getListAreasByEmpresa(this.persona.empresa.id);

    // inicializar datatable
    this.inicializeDatatable();

    this.$isLoading(false);
  },
  methods: {
    inicializeDatatable() {
      core.index();

      if (window.$.fn.DataTable.isDataTable("#datatable_managerMarket"))
        window.$("#datatable_managerMarket").DataTable().destroy();

      this.dataTableInstance = window.$("#datatable_managerMarket").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
    updateTableAndListItems(recentBeneficio) {
      this.listaItems = [...this.listaItems, recentBeneficio];

      // console.log("new beneficio", this.listaItems);

      // update listItems
      if (window.$.fn.DataTable.isDataTable("#datatable_managerMarket")) {
        window.$("#datatable_managerMarket").DataTable().destroy();
      }

      setTimeout(() => {
        this.dataTableInstance = window
          .$("#datatable_managerMarket")
          .DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
      }, 100);
    },

    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistroBeneficiosMarket/ByResponsableId/" + this.persona.id,
        });
        // console.log("list beneficios...", res);
        if (res.success) {
          this.listaItems = res.data.map((beneficio) => {
            return {
              ...beneficio,
              estado: beneficio.estado == 1 ? true : false,
            };
          });
          // console.log("update list", this.listaItems);
        }
      } catch (error) {
        console.log("err", error);
      }
    },

    async sendToggleEstado(itemId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistroBeneficiosMarket/ToggleBeneficioEstado/" + itemId,
        });
        // console.log("finalizarServicio...", res);
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async toggleActive(rowIndex, item) {
      // console.log(rowIndex, item);
      if (!item.estado) {
        Swal.fire({
          title: "Seguro de inactivar?",
          text: "Podrá activarlo nuevamente si desea!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#186bdd",
          cancelButtonColor: "rgb(242, 19, 93)",
          confirmButtonText: "Si, inactivar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          // console.log(result);
          if (result.isConfirmed) {
            const response = await this.sendToggleEstado(item.id);

            if (response.success) {
              this.$set(this.listaItems, rowIndex, {
                ...item,
                estado: false,
              });
              setTimeout(() => {
                this.dataTableInstance.row(rowIndex).invalidate().draw();
              }, 100);
              this.$vs.notification({
                //duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-circle-check'></i>`,
                color: "success",
                position: "top-right",
                title: "Beneficio inactivo!",
              });
            }
          } else {
            item.estado = true;
          }
        });
      } else {
        const response = await this.sendToggleEstado(item.id);

        if (response.success) {
          this.$set(this.listaItems, rowIndex, {
            ...item,
            estado: true,
          });
          setTimeout(() => {
            this.dataTableInstance.row(rowIndex).invalidate().draw();
          }, 100);
          this.$vs.notification({
            //duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "success",
            position: "top-right",
            title: "Beneficio activo!",
          });
        }
      }
    },
    async getListAreasByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Area/ListArea/" + empresaId,
        });
        // console.log("list areas by empresa...", res);

        if (res.length > 0) {
          // Usamos un Map para garantizar que los objetos sean únicos basándonos en la propiedad 'id'
          let uniqueAreasMap = new Map();
          res.forEach((area) => {
            uniqueAreasMap.set(area.descripcion, area);
          });
          this.listAreas = Array.from(uniqueAreasMap.values());

          // console.log(this.listAreas);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
