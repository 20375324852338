<template>
  <b-modal
    title="Enviar Coins"
    id="sendCoins"
    size="lg"
    hide-footer
    hide-header
    body-class="body-modal-custom"
  >
    <form @submit.prevent="sendCoins">
      <div class="d-flex mb-3">
        <vs-radio warn v-model="modeSend" val="1">
          <template #icon>
            <i class="fas fa-user"></i>
          </template>
          Individuales
        </vs-radio>
        <vs-radio success v-model="modeSend" val="2">
          <template #icon>
            <i class="fas fa-users"></i>
          </template>
          Por areas
        </vs-radio>
      </div>
      <div class="row align-items-center">
        <!-- selected personas -->
        <div class="col-12 mb-3">
          <label for="personas" class="form-label">
            <em class="font-weight-bolder">Personas</em>
            <br />
            <small class="text-white-50 ml-2">
              - Puede enviar coins a una persona o varias</small
            >
          </label>
          <!-- vuesax select multiple -->
          <vs-select
            color="warn"
            :disabled="modeSend == 2"
            class="custom-multiple"
            filter
            multiple
            v-model="sendDataCoins.personaIds"
            @click.native.prevent
          >
            <vs-option
              v-for="persona of listPersonasEmpresa"
              :key="persona.id"
              :label="persona.nombreDoc"
              :value="persona.id"
            >
              {{ persona.nombreDoc }}
            </vs-option>
          </vs-select>
        </div>
        <!-- selected areas -->
        <div
          class="col-12 mb-3"
          :class="sendDataCoins.areaIds.length > 0 ? '' : 'col-lg'"
        >
          <label for="areas" class="form-label">
            <em class="font-weight-bolder">Areas</em>
            <br />
            <small class="text-white-50 ml-2">
              - Puede enviar coins a un area o varias</small
            >
          </label>
          <!-- vuesax select multiple -->
          <vs-select
            color="success"
            :disabled="modeSend == 1"
            class="custom-multiple"
            filter
            multiple
            v-model="sendDataCoins.areaIds"
            @click.native.prevent
          >
            <vs-option
              v-for="area of listAreas"
              :key="area.id"
              :label="area.descripcion"
              :value="area.id"
            >
              {{ area.descripcion }}
            </vs-option>
          </vs-select>
        </div>
        <!-- exclude personas -->
        <div
          v-if="listPersonasAreasSeleccionadas.length > 0"
          class="col-12 col-lg-9 mb-3"
        >
          <label for="exclude" class="form-label">
            <em class="font-weight-bolder">Excluir personas</em>
            <br />
            <small class="text-white-50 ml-2">
              - Puede excluir a una persona o varias de las areas seleccionadas
              para que no reciban coins</small
            >
          </label>
          <!-- vuesax select multiple -->
          <vs-select
            color="danger"
            :disabled="modeSend == 1"
            class="custom-multiple"
            filter
            multiple
            v-model="sendDataCoins.excludePersonaIds"
            @click.native.prevent
          >
            <vs-option
              v-for="persona of listPersonasAreasSeleccionadas"
              :key="persona.id"
              :label="persona.nombreDoc"
              :value="persona.id"
            >
              {{ persona.nombreDoc }}
            </vs-option>
          </vs-select>
        </div>
        <!-- coins -->
        <div class="col-12 col-lg-3 mb-3">
          <label for="coins" class="form-label"
            ><em class="font-weight-bolder">Coins</em>
            <br />
            <small class="text-white-50 ml-2">
              - Cantidad de coins a enviar</small
            >
          </label>
          <b-form-input
            v-model="sendDataCoins.coins"
            type="number"
            placeholder="Ej: 35"
            required
          ></b-form-input>
        </div>
        <!-- descripcion -->
        <div class="col-12 mb-3 mt-2">
          <label for="descripcion" class="form-label"
            ><em class="font-weight-bolder">Descripción</em>
            <br />
            <small class="text-white-50 ml-2"> - Puede dejar una nota </small>
          </label>
          <b-form-textarea
            v-model="sendDataCoins.descripcion"
            placeholder="Ej: Ahora puedes canjear tus coins por increibles beneficios desde el market"
            rows="7"
            minlength="5"
            maxlength="550"
            required
          ></b-form-textarea>
        </div>
      </div>

      <!-- btn send coins -->
      <div class="d-flex justify-content-end mt-3">
        <vs-button
          :loading="isSendCoins"
          type="submit"
          class="py-1"
          animation-type="vertical"
           
          warn
          size="large"
        >
          Enviar coins
          <template #animate> <i class="fas fa-paper-plane"></i></template>
        </vs-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  props: {
    listAreas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modeSend: 1,
      persona: {},
      isSendCoins: false,
      sendDataCoins: {
        empresaId: null,
        personaIds: [],
        excludePersonaIds: [],
        areaIds: [],
        coins: null,
        descripcion: null,
      },
      listPersonasEmpresa: [
        {
          id: null,
          nombreDoc: null,
        },
      ],
      listPersonasAreasSeleccionadas: [
        {
          id: null,
          nombreDoc: null,
        },
      ],
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listPersonasAreasSeleccionadas = [];
    this.listPersonasEmpresa = [];
    await this.getListPersonasEmpresa(this.persona.empresa.id);
  },
  watch: {
    "sendDataCoins.areaIds": async function (newAreaIds) {
      await this.updatePersonasEnAreas(newAreaIds);
    },
  },
  methods: {
    async updatePersonasEnAreas(areaIds) {
      this.listPersonasAreasSeleccionadas = []; // Limpiar la lista antes de actualizar
      // console.log(areaIds);
      if (areaIds.length > 0) {
        let res = await this.$store.dispatch("hl_get", {
          path: `Persona/ListPersonasAreas/${areaIds}`,
        });
        // console.log(res);
        if (res.length > 0) {
          this.listPersonasAreasSeleccionadas = res.map((persona) => {
            return {
              id: persona.id,
              nombreDoc: `${persona.numeroDocumento} - ${persona.nombres} ${persona.apellidos}`,
            };
          });
        }
      }
    },
    initFormSendCoins() {
      this.sendDataCoins = {
        // Reiniciar todas las propiedades del objeto sendDataCoins
        empresaId: null,
        personaIds: [],
        excludePersonaIds: [],
        areaIds: [],
        coins: null,
        descripcion: null,
      };
    },
    async sendCoins() {
      try {
        this.isSendCoins = true;
        var dataSendCoins = {};

        // Validar si hay personas o áreas seleccionadas según el modo
        if (this.modeSend == 1) {
          this.validateSelectedPeople();
          dataSendCoins = this.prepareDataForSendCoins(
            this.sendDataCoins.personaIds
          );
        } else if (this.modeSend == 2) {
          this.validateSelectedAreas();
          dataSendCoins = this.prepareDataForSendCoins(
            null,
            this.sendDataCoins.areaIds,
            this.sendDataCoins.excludePersonaIds
          );
        }

        // Enviar la solicitud
        let res = await this.sendCoinsRequest(dataSendCoins);

        // Manejar la respuesta
        this.handleSendCoinsResponse(res);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSendCoins = false;
      }
    },

    validateSelectedPeople() {
      if (
        !this.sendDataCoins.personaIds ||
        this.sendDataCoins.personaIds.length === 0
      ) {
        throw new Error("No ha seleccionado ninguna persona!");
      }
    },

    validateSelectedAreas() {
      if (
        !this.sendDataCoins.areaIds ||
        this.sendDataCoins.areaIds.length === 0
      ) {
        throw new Error("No ha seleccionado ninguna área!");
      }
    },

    prepareDataForSendCoins(
      personaIds,
      areaIds = null,
      excludePersonaIds = null
    ) {
      return {
        coins: parseInt(this.sendDataCoins.coins),
        descripcion: this.sendDataCoins.descripcion,
        empresaId: this.persona.empresa.id,
        personaIds: personaIds ? personaIds.join() : null,
        areaIds: areaIds ? areaIds.join() : null,
        excludePersonaIds: excludePersonaIds ? excludePersonaIds.join() : null,
      };
    },

    async sendCoinsRequest(dataSendCoins) {
      return await this.$store.dispatch("hl_post", {
        path: "Persona/SendCoins/",
        data: dataSendCoins,
      });
    },

    handleSendCoinsResponse(res) {
      if (res.success) {
        this.handleSuccessResponse();
      } else {
        this.handleErrorResponse(res.message);
      }
    },

    async handleSuccessResponse() {
      this.initFormSendCoins(); // Limpiar formulario
      this.$bvModal.hide("sendCoins"); // Ocultar modal
      this.showNotification("Coins enviadas!", "primary");
    },

    handleErrorResponse(errorMessage) {
      this.showNotification("Ha ocurrido un error!", "danger", errorMessage);
    },

    showNotification(title, color, text = null) {
      this.$vs.notification({
        progress: "auto",
        icon: `<i class='fas fa-circle-check'></i>`,
        color: color,
        position: "top-right",
        title: title,
        text: text,
      });
    },

    handleError(error) {
      console.error("Error al enviar coins:", error);
      this.showNotification("Ha ocurrido un error!", "danger", error.message);
    },
    async getListPersonasEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `Persona/ListPersona/${empresaId}`,
        });
        // console.log("list personas empresa...", res);
        if (res.length > 0) {
          this.listPersonasEmpresa = res.map((persona) => {
            return {
              id: persona.id,
              nombreDoc: `${persona.numeroDocumento} - ${persona.nombres} ${persona.apellidos}`,
            };
          });
          // console.log(this.listPersonasEmpresa);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
};
</script>

<style scoped></style>
