<template>
  <b-modal
    id="history"
    size="xl"
    hide-footer
    hide-header
    body-class="body-modal-custom bg-dark"
  >
    <table class="table table-borderless">
      <caption>
        Historial de canjes
      </caption>
      <thead class="thead-dark">
        <tr>
          <th scope="col" class="rounded-left">#</th>
          <th scope="col">Persona</th>
          <th scope="col">Beneficio</th>
          <th scope="col">Categoría</th>
          <th scope="col">Coins</th>
          <th scope="col" class="rounded-right">Fecha</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(canje, i) in listCanjes" :key="canje.id">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ canje.persona }}</td>
          <td>{{ canje.beneficio }}</td>
          <td>{{ canje.categoria }}</td>
          <td>
            {{ canje.coins }}
            <img
              style="width: 20px"
              loading="lazy"
              src="/img/coins.png"
              alt="coins"
            />
          </td>
          <td>{{ canje.fecha | timeSince }}</td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import moment_timezone from "moment-timezone";
export default {
  data() {
    return {
      listCanjes: [
        {
          id: null,
          beneficioId: null,
          beneficio: null,
          personaId: null,
          areaId: null,
          categoria: null,
          coins: null,
          fecha: null,
          persona: null,
        },
      ],
    };
  },
  async mounted() {
    await this.getListCanjesAll();
  },
  methods: {
    async getListCanjesAll() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CanjesMarketLog/ListAll/",
        });
        // console.log("list canjes all...", res);
        if (res.success) {
          this.listCanjes = res.data.map((canje) => {
            return {
              ...canje,
              persona: `${canje.persona.nombres} ${canje.persona.apellidos}`,
            };
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style scoped></style>
