<template>
  <b-modal
    title="Nuevo Beneficio"
    id="addBeneficio"
    size="lg"
    hide-footer
    hide-header
    body-class="body-modal-custom"
  >
    <form @submit.prevent="saveData">
      <div class="row align-items-center">
        <div
          class="col-12 col-lg mt-2 d-flex flex-column justify-content-center align-items-center"
        >
          <label for=" " class="form-label">
            <em class="text-danger font-weight-bolder">Imagen vista previa:</em>
          </label>
          <!-- image preview -->
          <div class="image-container w-100 rounded bg-dark">
            <input
              type="file"
              ref="fileInput"
              @change="previewImage"
              class="file-input"
              @click.stop
            />
            <div
              v-if="!imagePreview"
              class="placeholder"
              @click="openFileInput"
            >
              Haz clic para seleccionar una imagen
            </div>
            <img
              v-if="imagePreview"
              :src="imagePreview"
              alt="Preview"
              class="preview-image"
            />
            <i
              v-if="imagePreview"
              @click="removeImage"
              class="fas fa-circle-minus position-absolute text-danger"
              style="
                top: 0;
                right: -30px;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
              "
            >
            </i>
          </div>
        </div>
        <div class="col-12 col-lg mt-4">
          <!-- nombre -->
          <div class="mb-3">
            <label for="marca" class="form-label"
              ><em class="text-danger font-weight-bolder">Paso 1</em> -
              Nombre</label
            >
            <b-form-input
              v-model="newBeneficio.Nombre"
              placeholder="Nombre del beneficio..."
              required
            ></b-form-input>
          </div>
          <!-- asignar a categoria -->
          <div class="mb-3">
            <div>
              <label for="categoria" class="form-label">
                <em class="text-danger font-weight-bolder">Paso 2</em> -
                Categoría</label
              >
              <!-- vue-select -->
              <div style="background: transparent; padding: 0rem">
                <v-select
                  v-model="newBeneficio.CategoriaId"
                  class="style-chooser"
                  :reduce="(value) => value.id"
                  :options="listaCategorias"
                  :get-option-label="(option) => `${option.nombre}`"
                  placeholder="-- seleccionar categoria --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!newBeneficio.CategoriaId"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay categorias para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- coins -->
          <div class="mb-3">
            <label for="coins" class="form-label"
              ><em class="text-danger font-weight-bolder">Paso 3</em> -
              Coins</label
            >
            <b-form-input
              v-model="newBeneficio.Coins"
              type="number"
              placeholder="Cantidad de coins..."
              required
            ></b-form-input>
          </div>
        </div>

        <!-- descripcion -->
        <div class="col-12 mb-3 mt-2">
          <label for="descripcion" class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 4</em> -
            Descripción</label
          >
          <b-form-textarea
            v-model="newBeneficio.Descripcion"
            placeholder="Escriba una descripción..."
            rows="7"
            minlength="10"
            maxlength="550"
          ></b-form-textarea>
        </div>
        <!-- stock -->
        <div class="col-12 col-lg-6 mb-3">
          <label for="coins" class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 5:</em> -
            Stock</label
          >
          <b-form-input
            v-model="newBeneficio.Stock"
            type="number"
            placeholder="Cantidad disponibles..."
            required
          ></b-form-input>
        </div>

        <!-- visibilidad -->
        <div class="col-12 col-lg-6 mb-3">
          <label for="visibilidad" class="form-label">
            <em class="text-danger font-weight-bolder">Paso 6:</em> -
            Visibilidad
          </label>
          <!-- vuesax select multiple -->
          <vs-select
            class="custom-multiple"
            filter
            multiple
            v-model="newBeneficio.VisibilidadAreaId"
            @click.native.prevent
          >
            <vs-option
              v-for="area of listAreas"
              :key="area.id"
              :label="area.descripcion"
              :value="area.id"
            >
              {{ area.descripcion }}
            </vs-option>
          </vs-select>
        </div>
      </div>
      <!-- btn agregar producto -->
      <div class="d-flex justify-content-end mt-3">
        <vs-button
          :loading="isCreating"
          type="submit"
          class="py-1"
          animation-type="vertical"
           
          size="large"
        >
          Agregar beneficio
          <template #animate> <i class="fas fa-plus"></i></template>
        </vs-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  props: {
    listAreas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      persona: {},
      imagePreview: null,
      file: null,
      fileName: null,
      isCreating: false,
      listaCategorias: [],
      newBeneficio: {
        Nombre: null,
        Descripcion: null,
        CategoriaId: null,
        Coins: null,
        Stock: null,
        ImagenPreview: null,
        VisibilidadAreaId: [],
        CreatedAt: null,
        ResponsableId: null,
        Estado: 1,
      },
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getListCategoriasMarket();
  },

  methods: {
    initFormNewBeneficio() {
      for (let prop in this.newBeneficio) {
        // console.log(prop);
        if (prop != "Estado") {
          this.newBeneficio[prop] = null;
        }
        if (prop == "VisibilidadAreaId") {
          this.newBeneficio[prop] = [];
        }
      }
      this.imagePreview = null;
    },
    async renameFile(nameFile) {
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadVistaPreviaImagenBeneficio() {
      try {
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let nameEmpresa = this.persona.empresa.nombre.replace(/\s/g, "");
          let url = `.|site1|BeneficiosMarket|${nameEmpresa}|previewImages`;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            // console.log("archivo subido...", uploadImg);
            return `https://www.helexiumfiles.com/BeneficiosMarket/${nameEmpresa}/previewImages/${this.filename}`;
          }
        }
      } catch (error) {
        console.log("errror upload", error);
      }
    },

    async saveData() {
      try {
        this.isCreating = !this.isCreating;
        const imageUrl = this.imagePreview
          ? await this.uploadVistaPreviaImagenBeneficio()
          : null;
        const newBeneficio = {
          ...this.newBeneficio,
          VisibilidadAreaId:
            this.newBeneficio.VisibilidadAreaId.length == 0
              ? null
              : this.newBeneficio.VisibilidadAreaId.join(),
          ResponsableId: this.persona.id,
          ImagenPreview: imageUrl,
          CreatedAt: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };

        // console.log("newBeneficio---------->", this.newBeneficio);

        let res = await this.$store.dispatch("hl_post", {
          path: "RegistroBeneficiosMarket/",
          data: newBeneficio,
        });
        // console.log("res new beneficio--------->", res);
        if (res.success) {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro guardado correctamente!",
          });
          this.initFormNewBeneficio(); //limpiar formulario
          this.isCreating = !this.isCreating;

          // cerrar modal
          this.$bvModal.hide("addBeneficio");

          let recentBeneficio = {
            ...res.data,
            estado: res.data.estado === 1 ? true : false,
          };

          // update table and listaItems
          this.$emit("updateData", recentBeneficio);
        } else {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-right",
            title: "Ha ocurrido un error!",
            text: res.errorDetail,
          });
          this.isCreating = !this.isCreating;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isCreating = !this.isCreating;
      }
    },
    async getListCategoriasMarket() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CategoriasMarket/List",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.listaCategorias = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      this.file = file;

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        console.error("Por favor, selecciona una imagen.");
      }
    },
    removeImage() {
      this.imagePreview = null;
      this.filterDataModalUpdateCurso.previewImagenUrl = null;
      // Restablecer el valor del input de archivo para permitir seleccionar la misma imagen nuevamente
      this.$refs.fileInput.value = null;
    },
  },
};
</script>

<style scoped>
.vista-previa {
  position: relative;
  width: 100%;
  min-height: 250px;
}

/* image preview */
.image-container {
  position: relative;
  height: 250px;
  border: 2px dashed #3498db;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777;
  cursor: pointer;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}
</style>
